<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <wolk-private-data-alert />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Vallen

            <v-spacer />

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Zoeken"
              single-line
              hide-details
            />
            <v-spacer />
          </v-card-title>
          <v-card-subtitle>
            <v-col
              md="2"
              class="px-0"
            >
              <v-select
                v-model="type"
                :items="[
                  {value: null, text: 'Alles'},
                  {value: 'fall', text: 'Vallen met activaties'},
                  {value: 'postFall', text: 'Overige vallen'}
                ]"
                item-text="text"
                item-value="value"
                label="Filteren"
              />
            </v-col>
          </v-card-subtitle>

          <v-data-table
            :headers="headers"
            :options.sync="tableSort"
            :items="filteredEvents"
            :items-per-page="20"
            :footer-props="{ itemsPerPageOptions: [10, 20, 50, 200] }"
            :loading="loading"
          >
            <template #[`item.createdAt`]="{ item }">
              {{ getHumanizedDate(item.createdAt) }}
            </template>

            <template #[`item.type`]="{ item }">
              {{ getType(item) }}
            </template>

            <template #[`item.wolk.metaData.sleevePublicId`]="{ item }">
              <template
                v-if="item.wolk && item.wolk.metaData && item.wolk.metaData.sleevePublicId"
              >
                {{ item.wolk.metaData.sleevePublicId }}
              </template>
            </template>

            <template #[`item.organization.title`]="{ item }">
              <template v-if="item.organization && item.organization.title">
                {{ item.organization.title }}
              </template>
            </template>

            <template #[`item.location.title`]="{ item }">
              <template v-if="item.location">
                {{ item.location.title }}
              </template>
            </template>

            <template #[`item.side`]="{ item }">
              <template v-if="hasFallDataAnalysesExpired(item) && isActivated(item)">
                <activation-detail-chip
                  color="#D8D8D8"
                  dialog-type="dataReceivingExpiredWithActivation"
                  text="--"
                  tooltip="Zie toelichting"
                />
              </template>

              <template v-else-if="hasFallDataAnalysesExpired(item) && !isActivated(item)">
                <activation-detail-chip
                  color="#D8D8D8"
                  dialog-type="dataReceivingExpiredWithoutActivation"
                  text="--"
                  tooltip="Zie toelichting"
                />
              </template>

              <template v-else-if="!hasFallDataAnalysesResult(item)">
                <activation-detail-chip
                  color="#D8D8D8"
                  dialog-type="dataStillExpected"
                  text="?"
                  tooltip="Zie instructies"
                />
              </template>
              <template v-else>
                <v-chip
                  v-if="isHipFall(item)"
                  color="#CCDAF6"
                >
                  {{
                    item.fallData.fallEvent ?
                      getHumanizedSide(item) :
                      'unknown'
                  }}
                </v-chip>
                <v-chip
                  v-else
                  color="#D8D8D8"
                >
                  {{
                    item.fallData.fallEvent ?
                      getHumanizedSide(item) :
                      'unknown'
                  }}
                </v-chip>
              </template>
            </template>

            <template #[`item.fall`]="{ item }">
              {{ item.fallData.fallEvent && item.fallData.fallEvent.postFall === 3 ? 'Ja' : 'Nee' }}
            </template>

            <template #[`item.fallPostProcess`]="{ item }">
              <template v-if="hasFallDataAnalysesExpired(item) && isActivated(item)">
                <activation-detail-chip
                  color="#D8D8D8"
                  dialog-type="dataReceivingExpiredWithActivation"
                  text="--"
                  tooltip="Zie toelichting"
                />
              </template>

              <template v-else-if="hasFallDataAnalysesExpired(item) && !isActivated(item)">
                <activation-detail-chip
                  color="#D8D8D8"
                  dialog-type="dataReceivingExpiredWithoutActivation"
                  text="--"
                  tooltip="Zie toelichting"
                />
              </template>

              <template v-else-if="!hasFallDataAnalysesResult(item)">
                <activation-detail-chip
                  color="#D8D8D8"
                  dialog-type="dataStillExpected"
                  text="?"
                  tooltip="Zie instructies"
                />
              </template>

              <template
                v-else-if="isValidatedFall(item)
                  && isHipFall(item)
                  && isHardOrVeryHard(item)
                  && isActivated(item)
                  && isValidatedOnTime(item)"
              >
                <v-chip
                  color="#9FCE63"
                >
                  Ja
                </v-chip>
              </template>

              <template v-else-if="isValidatedFall(item)">
                <v-chip
                  color="#D8D8D8"
                >
                  Ja
                </v-chip>
              </template>

              <template v-else-if="isPostFall(item)">
                <activation-detail-chip
                  color="#F9DB77"
                  dialog-type="notFallInPostProcessingPostFall"
                  text="Nee"
                  tooltip="Zie toelichting"
                />
              </template>

              <template v-else>
                <activation-detail-chip
                  color="#F9DB77"
                  dialog-type="notFallInPostProcessing"
                  text="Nee"
                  tooltip="Zie toelichting"
                />
              </template>
            </template>

            <template #[`item.onTime`]="{ item }">
              <template
                v-if="(isSoftOrVerySoft(item)
                  && !isPostFall(item)
                  && isValidatedFall(item))
                  && isActivated(item)"
              >
                <v-chip
                  color="#D8D8D8"
                >
                  Ja
                </v-chip>
              </template>

              <template
                v-else-if="(!isPostFall(item) && isValidatedFall(item) && !isSoftOrVerySoft(item)
                  && (getHumanizedSide(item) === 'Naar achteren'
                    || getHumanizedSide(item) === 'Naar voren')) && isActivated(item)"
              >
                <activation-detail-chip
                  dialog-type="notApplicable"
                  text="Ja"
                  tooltip="Zie toelichting"
                  color="#D8D8D8"
                />
              </template>
              <template
                v-else-if="(item.fallData.fallEvent.side === 0
                  && (getHumanizedSide(item) === 'Naar achteren'
                    || getHumanizedSide(item) === 'Naar voren'))"
              >
                <activation-detail-chip
                  dialog-type="nonHipFall"
                  text="Nee"
                  tooltip="Zie toelichting"
                  color="#D8D8D8"
                />
              </template>

              <template
                v-else-if="
                  item
                    .fallData
                    .fallEvent
                    .side === 0
                    && (getHumanizedSide(item) === 'Heupval - links'
                      || getHumanizedSide(item) === 'Heupval - rechts')"
              >
                <activation-detail-chip
                  dialog-type="notActivated"
                  text="Nee"
                  tooltip="Zie toelichting"
                  color="#F9DA77"
                />
              </template>

              <template
                v-else-if="isValidatedFall(item)
                  && isHipFall(item)
                  && isHardOrVeryHard(item)
                  && isActivated(item)
                  && isValidatedOnTime(item)"
              >
                <v-chip
                  color="#9FCE63"
                >
                  Ja
                </v-chip>
              </template>

              <template v-else-if="hasFallDataAnalysesExpired(item) && isActivated(item)">
                <activation-detail-chip
                  color="#D8D8D8"
                  dialog-type="dataReceivingExpiredWithActivation"
                  text="Ja"
                  tooltip="Zie toelichting"
                />
              </template>

              <template v-else-if="hasFallDataAnalysesExpired(item) && !isActivated(item)">
                <activation-detail-chip
                  color="#D8D8D8"
                  dialog-type="dataReceivingExpiredWithoutActivation"
                  text="Nee"
                  tooltip="Zie toelichting"
                />
              </template>

              <template v-else-if="!hasFallDataAnalysesResult(item) && isActivated(item)">
                <activation-detail-chip
                  color="#D8D8D8"
                  dialog-type="dataStillExpected"
                  text="Ja"
                  tooltip="Zie instructies"
                />
              </template>

              <template v-else-if="!hasFallDataAnalysesResult(item) && !isActivated(item)">
                <activation-detail-chip
                  color="#D8D8D8"
                  dialog-type="dataStillExpected"
                  text="Nee"
                  tooltip="Zie instructies"
                />
              </template>

              <template v-else-if="!isValidatedFall(item) && isPostFall(item)">
                <v-chip
                  color="#D8D8D8"
                >
                  Nee
                </v-chip>
              </template>

              <template
                v-else-if="!isValidatedFall(item)
                  && !isPostFall(item) && isActivated(item)"
              >
                <v-chip
                  color="#D8D8D8"
                >
                  Ja
                </v-chip>
              </template>

              <template
                v-else-if="!isPostFall(item) && !isActivated(item)"
              >
                <activation-detail-chip
                  color="#EA3323"
                  dialog-type="noCartridge"
                  text="Geen patroon"
                  tooltip="Zie toelichting"
                />
              </template>

              <template
                v-else-if="
                  item
                    .fallData
                    .fallDataAnalysesResult
                    .motionTracking
                    .labels
                    .leadTime === 'ok'
                "
              >
                <v-chip
                  color="#D8D8D8"
                >
                  Ja
                </v-chip>
              </template>

              <template v-else>
                <activation-detail-chip
                  color="#F9DB77"
                  dialog-type="notOnTimeActivated"
                  text="Laat"
                  tooltip="Zie toelichting"
                />
              </template>
            </template>

            <template #[`item.fallIntensity`]="{ item }">
              <template v-if="hasFallDataAnalysesExpired(item) && isActivated(item)">
                <activation-detail-chip
                  color="#D8D8D8"
                  dialog-type="dataReceivingExpiredWithActivation"
                  text="--"
                  tooltip="Zie toelichting"
                />
              </template>

              <template v-else-if="hasFallDataAnalysesExpired(item) && !isActivated(item)">
                <activation-detail-chip
                  color="#D8D8D8"
                  dialog-type="dataReceivingExpiredWithoutActivation"
                  text="--"
                  tooltip="Zie toelichting"
                />
              </template>

              <template v-else-if="getHumanizedSide(item) === 'N.v.t.'">
                <v-chip
                  color="#D8D8D8"
                >
                  N.v.t.
                </v-chip>
              </template>

              <template v-else-if="!hasFallDataAnalysesResult(item)">
                <activation-detail-chip
                  color="#D8D8D8"
                  dialog-type="dataStillExpected"
                  text="?"
                  tooltip="Zie instructies"
                />
              </template>

              <template v-else-if="!isValidatedFall(item)">
                <v-chip
                  color="#D8D8D8"
                >
                  N.v.t.
                </v-chip>
              </template>

              <template v-else>
                <v-chip
                  :color="getValidatedFallIntensity(item).color"
                >
                  {{ getValidatedFallIntensity(item).text }}
                </v-chip>
              </template>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-icon
                disabled
                class="mr-2"
                @click="$router.push({ name: 'eventsShow', params: { id: item._id } })"
              >
                mdi-eye
              </v-icon>

              <v-icon
                disabled
                class="mr-2"
                @click="$router.push({ name: 'home' })"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

import { createBreadcrumbs, getHumanizedFallIntensity, getHumanizedSide } from '@/helpers/humanizer';

import WolkPrivateDataAlert from '../components/WolkPrivateDataAlert.vue';
import ActivationDetailChip from '../components/ActivationDetailChip.vue';

export default {
  name: 'ActivationsPage',
  components: {
    ActivationDetailChip,
    WolkPrivateDataAlert,
  },
  data() {
    return {
      search: '',
      type: null,
      loading: false,
      tableSort: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['createdAt'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
      },
      events: [],
      searchedEvents: [],
    };
  },
  computed: {
    filter() {
      return { search: this.search, type: this.type };
    },
    breadcrumbs() {
      return createBreadcrumbs(
        this.$store.state.app.dashboardHomeName,
        [
          {
            text: 'Activations',
            disabled: true,
            exact: true,
            to: { name: 'activations' },
          },
        ],
        false,
      );
    },
    filteredEvents() {
      return this.searchEvents(this.filter);
    },
    headers() {
      if (this.$store.state.auth.type === 'user') {
        return [
          { text: 'Wolk', value: 'sleevePublicId' },
          { text: 'Locatie', value: 'location.title' },
          { text: 'ID', value: 'customerIdentifier', sortable: false },
          { text: 'Val ?', value: 'fallPostProcess', sortable: false },
          { text: 'Richting', value: 'side', sortable: false },
          { text: 'Valsnelheid', value: 'fallIntensity', sortable: false },
          { text: 'Activatie', value: 'onTime', sortable: false },
          { text: 'Aangemaakt op', value: 'createdAt' },
        ];
      }

      return [
        { text: 'Wolk', value: 'sleevePublicId' },
        { text: 'Organisatie', value: 'organization.title' },
        { text: 'Locatie', value: 'location.title' },
        { text: 'ID', value: 'customerIdentifier', sortable: false },
        { text: 'Val ?', value: 'fallPostProcess', sortable: false },
        { text: 'Richting', value: 'side', sortable: false },
        { text: 'Valsnelheid', value: 'fallIntensity', sortable: false },
        { text: 'Activatie', value: 'onTime', sortable: false },
        { text: 'Aangemaakt op', value: 'createdAt' },
      ];
    },
  },
  beforeMount() {
    this.loadEvents();
  },
  methods: {
    isSoftOrVerySoft(event) {
      return event
        ?.fallData
        ?.fallDataAnalysesResult
        ?.motionTracking
        ?.labels
        ?.fallIntensity
        ?.includes('soft');
    },
    isHardOrVeryHard(event) {
      return event
        ?.fallData
        ?.fallDataAnalysesResult
        ?.motionTracking
        ?.labels
        ?.fallIntensity
        ?.includes('hard');
    },
    isPostFall(event) {
      return event?.fallData?.fallEvent?.side === 0 && event?.fallData?.fallEvent?.postFallDir > 0;
    },
    isActivated(event) {
      if (event.fallData?.fallEvent) {
        return 'activation' in event.fallData.fallEvent ? event.fallData.fallEvent.activation : true;
      }
      return null;
    },
    getHumanizedSide(item) {
      const analysedType = item?.fallData?.fallDataAnalysesResult?.motionTracking?.labels?.type;
      const fallEvent = item?.fallData?.fallEvent;

      if (!fallEvent) return null;
      let { side } = fallEvent;
      if (fallEvent.postFallDir > 0 && fallEvent.side === 0) {
        side = fallEvent.postFallDir;
      }

      return getHumanizedSide(side, analysedType, item?.type);
    },
    getHumanizedFallIntensity(key) {
      return getHumanizedFallIntensity(key);
    },
    getHumanizedDate(millis) {
      return new Date(millis).toLocaleString('nl-NL');
    },
    hasFallDataAnalysesExpired(event) {
      return !!event.fallData.fallDataAnalysesExpiredAt;
    },
    hasFallDataAnalysesResult(event) {
      return !!event.fallData.fallDataAnalysesResult;
    },
    isHipFall(item) {
      return this.getHumanizedSide(item).includes('Heupval');
    },
    isValidatedFall(event) {
      if (!event.fallData.fallDataAnalysesResult) {
        return false;
      }

      const { type } = event
        .fallData
        .fallDataAnalysesResult
        .motionTracking
        .labels;

      if (typeof type !== 'string') {
        return false;
      }

      return ['hip fall', 'hip fall left', 'hip fall right', 'non-hip fall forward', 'non-hip fall backward'].includes(type);
    },
    isValidatedOnTime(event) {
      if (!event.fallData.fallDataAnalysesResult) {
        return false;
      }

      return event
        .fallData
        .fallDataAnalysesResult
        .motionTracking
        .labels
        .leadTime === 'ok';
    },
    getValidatedFallIntensity(event) {
      if (!event.fallData.fallDataAnalysesResult) {
        return null;
      }

      return getHumanizedFallIntensity(
        event
          .fallData
          .fallDataAnalysesResult
          .motionTracking
          .labels
          .fallIntensity,
      );
    },
    getType(event) {
      if (event.type) {
        return event.type;
      }

      if (event.heartbeat) {
        return 'heartbeat';
      }

      return 'fallevent';
    },
    searchEvents(filter) {
      if (this.isDefaultFilter(filter)) {
        return this.events;
      }
      if (!this.isFilterChanged(filter)) {
        return this.searchedEvents;
      }
      this.loadEvents(filter);
      this.previousFilter = filter;
      return this.searchedEvents;
    },
    loadEvents(filter) {
      if (this.loadEventsCancelToken) {
        this.loadEventsCancelToken();
      }

      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/events`;
      const params = {
        type: ['fall', 'postFall'],
      };

      if ((filter || {}).search) {
        params.search = filter.search;
        this.searchedEvents = [];
      }
      if ((filter || {}).type) {
        params.type = [filter.type];
      }

      if (this.$store.state.auth.type === 'user') {
        params.organizationId = this.$store.state.auth.metaData.organizationId;
      }

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
        params,
        cancelToken: new axios.CancelToken((c) => {
          this.loadEventsCancelToken = c;
        }),
      })
        .then((response) => {
          const results = response.data.events.map((e) => ({
            ...e,
            location: response
              .data
              .locations
              .find((l) => l._id === e.references?.locationId),
            organization: response
              .data
              .organizations
              .find((l) => l._id === e.references?.organizationId),
            wolk: response
              .data
              .wolks
              .find((l) => l._id === e.legacyReferences?.wolkId),
          })).map((e) => ({
            ...e,
            sleevePublicId: e.sleevePublicId ? e.sleevePublicId : e.wolk?.metaData?.sleevePublicId,
          })).filter((e) => {
            if (e.fallData?.fallEvent) {
              const activation = 'activation' in e.fallData.fallEvent ? e.fallData.fallEvent.activation : true;
              return !(e.wolk?.type === 'Belt' && !activation);
            }
            return false;
          });
          if (filter && !this.isDefaultFilter(filter)) {
            this.searchedEvents = results;
          } else {
            this.events = results;
          }
          this.loading = false;
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            return;
          }
          console.log(error);
          this.$store.commit('app/snackbarText', {
            text: 'Kon events niet laden.',
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', false);
          this.loading = false;
        });
    },
    isDefaultFilter(filter) {
      return filter.search === '' && filter.type === null;
    },
    isFilterChanged(filter) {
      if (!this.previousFilter) {
        return true;
      }

      return filter.search !== this.previousFilter.search
        || filter.type !== this.previousFilter.type;
    },
  },
};
</script>
