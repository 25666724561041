var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.breadcrumbs,"large":""}})],1)],1),_c('v-row',[_c('v-col',[_c('wolk-private-data-alert')],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Vallen "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Zoeken","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('v-card-subtitle',[_c('v-col',{staticClass:"px-0",attrs:{"md":"2"}},[_c('v-select',{attrs:{"items":[
                {value: null, text: 'Alles'},
                {value: 'fall', text: 'Vallen met activaties'},
                {value: 'postFall', text: 'Overige vallen'}
              ],"item-text":"text","item-value":"value","label":"Filteren"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.tableSort,"items":_vm.filteredEvents,"items-per-page":20,"footer-props":{ itemsPerPageOptions: [10, 20, 50, 200] },"loading":_vm.loading},on:{"update:options":function($event){_vm.tableSort=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getHumanizedDate(item.createdAt))+" ")]}},{key:"item.type",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getType(item))+" ")]}},{key:"item.wolk.metaData.sleevePublicId",fn:function(ref){
              var item = ref.item;
return [(item.wolk && item.wolk.metaData && item.wolk.metaData.sleevePublicId)?[_vm._v(" "+_vm._s(item.wolk.metaData.sleevePublicId)+" ")]:_vm._e()]}},{key:"item.organization.title",fn:function(ref){
              var item = ref.item;
return [(item.organization && item.organization.title)?[_vm._v(" "+_vm._s(item.organization.title)+" ")]:_vm._e()]}},{key:"item.location.title",fn:function(ref){
              var item = ref.item;
return [(item.location)?[_vm._v(" "+_vm._s(item.location.title)+" ")]:_vm._e()]}},{key:"item.side",fn:function(ref){
              var item = ref.item;
return [(_vm.hasFallDataAnalysesExpired(item) && _vm.isActivated(item))?[_c('activation-detail-chip',{attrs:{"color":"#D8D8D8","dialog-type":"dataReceivingExpiredWithActivation","text":"--","tooltip":"Zie toelichting"}})]:(_vm.hasFallDataAnalysesExpired(item) && !_vm.isActivated(item))?[_c('activation-detail-chip',{attrs:{"color":"#D8D8D8","dialog-type":"dataReceivingExpiredWithoutActivation","text":"--","tooltip":"Zie toelichting"}})]:(!_vm.hasFallDataAnalysesResult(item))?[_c('activation-detail-chip',{attrs:{"color":"#D8D8D8","dialog-type":"dataStillExpected","text":"?","tooltip":"Zie instructies"}})]:[(_vm.isHipFall(item))?_c('v-chip',{attrs:{"color":"#CCDAF6"}},[_vm._v(" "+_vm._s(item.fallData.fallEvent ? _vm.getHumanizedSide(item) : 'unknown')+" ")]):_c('v-chip',{attrs:{"color":"#D8D8D8"}},[_vm._v(" "+_vm._s(item.fallData.fallEvent ? _vm.getHumanizedSide(item) : 'unknown')+" ")])]]}},{key:"item.fall",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.fallData.fallEvent && item.fallData.fallEvent.postFall === 3 ? 'Ja' : 'Nee')+" ")]}},{key:"item.fallPostProcess",fn:function(ref){
              var item = ref.item;
return [(_vm.hasFallDataAnalysesExpired(item) && _vm.isActivated(item))?[_c('activation-detail-chip',{attrs:{"color":"#D8D8D8","dialog-type":"dataReceivingExpiredWithActivation","text":"--","tooltip":"Zie toelichting"}})]:(_vm.hasFallDataAnalysesExpired(item) && !_vm.isActivated(item))?[_c('activation-detail-chip',{attrs:{"color":"#D8D8D8","dialog-type":"dataReceivingExpiredWithoutActivation","text":"--","tooltip":"Zie toelichting"}})]:(!_vm.hasFallDataAnalysesResult(item))?[_c('activation-detail-chip',{attrs:{"color":"#D8D8D8","dialog-type":"dataStillExpected","text":"?","tooltip":"Zie instructies"}})]:(_vm.isValidatedFall(item)
                && _vm.isHipFall(item)
                && _vm.isHardOrVeryHard(item)
                && _vm.isActivated(item)
                && _vm.isValidatedOnTime(item))?[_c('v-chip',{attrs:{"color":"#9FCE63"}},[_vm._v(" Ja ")])]:(_vm.isValidatedFall(item))?[_c('v-chip',{attrs:{"color":"#D8D8D8"}},[_vm._v(" Ja ")])]:(_vm.isPostFall(item))?[_c('activation-detail-chip',{attrs:{"color":"#F9DB77","dialog-type":"notFallInPostProcessingPostFall","text":"Nee","tooltip":"Zie toelichting"}})]:[_c('activation-detail-chip',{attrs:{"color":"#F9DB77","dialog-type":"notFallInPostProcessing","text":"Nee","tooltip":"Zie toelichting"}})]]}},{key:"item.onTime",fn:function(ref){
                var item = ref.item;
return [((_vm.isSoftOrVerySoft(item)
                && !_vm.isPostFall(item)
                && _vm.isValidatedFall(item))
                && _vm.isActivated(item))?[_c('v-chip',{attrs:{"color":"#D8D8D8"}},[_vm._v(" Ja ")])]:((!_vm.isPostFall(item) && _vm.isValidatedFall(item) && !_vm.isSoftOrVerySoft(item)
                && (_vm.getHumanizedSide(item) === 'Naar achteren'
                  || _vm.getHumanizedSide(item) === 'Naar voren')) && _vm.isActivated(item))?[_c('activation-detail-chip',{attrs:{"dialog-type":"notApplicable","text":"Ja","tooltip":"Zie toelichting","color":"#D8D8D8"}})]:((item.fallData.fallEvent.side === 0
                && (_vm.getHumanizedSide(item) === 'Naar achteren'
                  || _vm.getHumanizedSide(item) === 'Naar voren')))?[_c('activation-detail-chip',{attrs:{"dialog-type":"nonHipFall","text":"Nee","tooltip":"Zie toelichting","color":"#D8D8D8"}})]:(
                item
                  .fallData
                  .fallEvent
                  .side === 0
                  && (_vm.getHumanizedSide(item) === 'Heupval - links'
                    || _vm.getHumanizedSide(item) === 'Heupval - rechts'))?[_c('activation-detail-chip',{attrs:{"dialog-type":"notActivated","text":"Nee","tooltip":"Zie toelichting","color":"#F9DA77"}})]:(_vm.isValidatedFall(item)
                && _vm.isHipFall(item)
                && _vm.isHardOrVeryHard(item)
                && _vm.isActivated(item)
                && _vm.isValidatedOnTime(item))?[_c('v-chip',{attrs:{"color":"#9FCE63"}},[_vm._v(" Ja ")])]:(_vm.hasFallDataAnalysesExpired(item) && _vm.isActivated(item))?[_c('activation-detail-chip',{attrs:{"color":"#D8D8D8","dialog-type":"dataReceivingExpiredWithActivation","text":"Ja","tooltip":"Zie toelichting"}})]:(_vm.hasFallDataAnalysesExpired(item) && !_vm.isActivated(item))?[_c('activation-detail-chip',{attrs:{"color":"#D8D8D8","dialog-type":"dataReceivingExpiredWithoutActivation","text":"Nee","tooltip":"Zie toelichting"}})]:(!_vm.hasFallDataAnalysesResult(item) && _vm.isActivated(item))?[_c('activation-detail-chip',{attrs:{"color":"#D8D8D8","dialog-type":"dataStillExpected","text":"Ja","tooltip":"Zie instructies"}})]:(!_vm.hasFallDataAnalysesResult(item) && !_vm.isActivated(item))?[_c('activation-detail-chip',{attrs:{"color":"#D8D8D8","dialog-type":"dataStillExpected","text":"Nee","tooltip":"Zie instructies"}})]:(!_vm.isValidatedFall(item) && _vm.isPostFall(item))?[_c('v-chip',{attrs:{"color":"#D8D8D8"}},[_vm._v(" Nee ")])]:(!_vm.isValidatedFall(item)
                && !_vm.isPostFall(item) && _vm.isActivated(item))?[_c('v-chip',{attrs:{"color":"#D8D8D8"}},[_vm._v(" Ja ")])]:(!_vm.isPostFall(item) && !_vm.isActivated(item))?[_c('activation-detail-chip',{attrs:{"color":"#EA3323","dialog-type":"noCartridge","text":"Geen patroon","tooltip":"Zie toelichting"}})]:(
                item
                  .fallData
                  .fallDataAnalysesResult
                  .motionTracking
                  .labels
                  .leadTime === 'ok'
              )?[_c('v-chip',{attrs:{"color":"#D8D8D8"}},[_vm._v(" Ja ")])]:[_c('activation-detail-chip',{attrs:{"color":"#F9DB77","dialog-type":"notOnTimeActivated","text":"Laat","tooltip":"Zie toelichting"}})]]}},{key:"item.fallIntensity",fn:function(ref){
              var item = ref.item;
return [(_vm.hasFallDataAnalysesExpired(item) && _vm.isActivated(item))?[_c('activation-detail-chip',{attrs:{"color":"#D8D8D8","dialog-type":"dataReceivingExpiredWithActivation","text":"--","tooltip":"Zie toelichting"}})]:(_vm.hasFallDataAnalysesExpired(item) && !_vm.isActivated(item))?[_c('activation-detail-chip',{attrs:{"color":"#D8D8D8","dialog-type":"dataReceivingExpiredWithoutActivation","text":"--","tooltip":"Zie toelichting"}})]:(_vm.getHumanizedSide(item) === 'N.v.t.')?[_c('v-chip',{attrs:{"color":"#D8D8D8"}},[_vm._v(" N.v.t. ")])]:(!_vm.hasFallDataAnalysesResult(item))?[_c('activation-detail-chip',{attrs:{"color":"#D8D8D8","dialog-type":"dataStillExpected","text":"?","tooltip":"Zie instructies"}})]:(!_vm.isValidatedFall(item))?[_c('v-chip',{attrs:{"color":"#D8D8D8"}},[_vm._v(" N.v.t. ")])]:[_c('v-chip',{attrs:{"color":_vm.getValidatedFallIntensity(item).color}},[_vm._v(" "+_vm._s(_vm.getValidatedFallIntensity(item).text)+" ")])]]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"disabled":""},on:{"click":function($event){return _vm.$router.push({ name: 'eventsShow', params: { id: item._id } })}}},[_vm._v(" mdi-eye ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"disabled":""},on:{"click":function($event){return _vm.$router.push({ name: 'home' })}}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }