<template>
  <div>
    <v-tooltip
      v-if="tooltip"
      top
    >
      <template #activator="{ on, attrs }">
        <v-chip
          :color="color"
          v-bind="attrs"
          v-on="on"
          @click="dialogOpen = true;"
        >
          {{ text }}

          <v-icon
            v-if="dialogType"
            right
          >
            mdi-information-outline
          </v-icon>
        </v-chip>
      </template>

      <span>{{ tooltip }}</span>
    </v-tooltip>

    <v-chip
      v-else
      :color="color"
      @click="dialogOpen = true;"
    >
      {{ text }}

      <v-icon
        v-if="dialogType"
        right
      >
        mdi-information-outline
      </v-icon>
    </v-chip>

    <v-dialog
      v-if="dialogType"
      v-model="dialogOpen"
      width="400"
    >
      <v-card>
        <template v-if="dialogType === 'dataStillExpected'">
          <v-card-title class="headline grey lighten-2">
            Data ontvangen
          </v-card-title>

          <v-card-text class="mt-4">
            <p>
              De data is (nog) niet binnen gekomen.
            </p>

            <p>
              Leg de Wolk aan de oplader en kijk of de data alsnog doorkomt. Als de Wolk een belt
              betreft, sluit deze met behulp van het klittenband.
            </p>
          </v-card-text>
        </template>

        <template v-else-if="dialogType === 'dataReceivingExpiredWithActivation'">
          <v-card-title class="headline grey lighten-2">
            Data niet ontvangen
          </v-card-title>

          <v-card-text class="mt-4">
            <p>
              Wolk is geactiveerd, maar vervolgens is
              de data helaas niet goed ontvangen op de server.
            </p>
          </v-card-text>
        </template>

        <template v-else-if="dialogType === 'dataReceivingExpiredWithoutActivation'">
          <v-card-title class="headline grey lighten-2">
            Data niet ontvangen
          </v-card-title>

          <v-card-text class="mt-4">
            <p>
              Wolk heeft een val gedetecteerd, maar vervolgens is
              de data helaas niet goed ontvangen op de server.
            </p>
          </v-card-text>
        </template>

        <template v-else-if="dialogType === 'notFallInPostProcessing'">
          <v-card-title class="headline grey lighten-2">
            Onterechte activatie
          </v-card-title>

          <v-card-text class="mt-4">
            <p>
              Wolk is helaas onterecht geactiveerd. Onze excuses voor het ongemak.
            </p>

            <p>
              De situatie wordt onderzocht om het val-algoritme te verbeteren.
            </p>

            <p>
              Was het wel een val? Wij ontvangen er graag een melding van via
              <a href="mailto:feedback@wolkairbag.com">feedback@wolkairbag.com</a>
              Bij voorbaat dank.
            </p>
          </v-card-text>
        </template>

        <template v-else-if="dialogType === 'notFallInPostProcessingPostFall'">
          <v-card-title class="headline grey lighten-2">
            Geen activatie
          </v-card-title>

          <v-card-text class="mt-4">
            <p>
              De Wolk dacht dat er een val voor- of achterover plaatsvond, maar uit onze
              analyse blijkt dit een vals alarm te zijn geweest. De Wolk is in dit geval
              ook niet geactiveerd, dus u hoeft geen actie te ondernemen.
            </p>
          </v-card-text>
        </template>

        <template v-else-if="dialogType === 'notOnTimeActivated'">
          <v-card-title class="headline grey lighten-2">
            Activatie niet op tijd
          </v-card-title>

          <v-card-text class="mt-4">
            <p>
              De airbag van Wolk heeft 0,1 seconde nodig om volledig op te blazen.
              Als een heupval "laat" gedetecteerd wordt kan het zijn dat de Wolk
              niet genoeg tijd heeft om, voordat cliënt de grond raakt, volledig op te blazen.
            </p>

            <p>
              Hoe kan dit voorkomen?
            </p>

            <ul>
              <li>
                Vb1: Client valt eerst naar voren en draait op het laatste moment naar de zijkant.
                Door de late zijwaartse beweging heeft de Wolk niet genoeg tijd om volledig
                op te blazen.
              </li>
            </ul>

            <p>
              In uitzonderlijke gevallen activeert Wolk laat bij een harde of zeer harde val:
              deze situatie zal altijd door Wolk worden onderzocht om het
              val-algoritme te verbeteren. Bij zachte en zeer zachte vallen wordt
              het onderscheid laat / op tijd niet gemaakt omdat dit vallen zijn met zeer laag
              risico op heupletsel.
            </p>
          </v-card-text>
        </template>

        <template v-else-if="dialogType === 'notActivated'">
          <v-card-title class="headline grey lighten-2">
            Niet geactiveerd
          </v-card-title>

          <v-card-text class="mt-4">
            <p>
              De Wolk dacht dat het hier niet om een heupval ging maar om een val naar voren
              of achteren. Daarom is de Wolk niet geactiveerd. Uit onze analyse is toch gebleken
              dat dit wel een val op de heup was. Bij (zeer) zachte vallen is de kans op heupletsel
              erg klein, en activeert de Wolk dus niet altijd. Gaat het hier om een
              (zeer) harde val, dan zal deze situatie door ons worden onderzocht.
            </p>
          </v-card-text>
        </template>

        <template v-else-if="dialogType === 'notApplicable'">
          <v-card-title class="headline grey lighten-2">
            Onterechte activatie
          </v-card-title>

          <v-card-text class="mt-4">
            <p>
              De Wolk dacht dat de cliënt op de heup viel, maar uiteindelijk was de val toch
              recht naar voren of achteren. De Wolk had dus niet hoeven activeren, omdat
              de Wolk geen bescherming biedt bij een val voor- of achterover.
            </p>
          </v-card-text>
        </template>

        <template v-else-if="dialogType === 'noCartridge'">
          <v-card-title class="headline grey lighten-2">
            Geen patroon
          </v-card-title>

          <v-card-text class="mt-4">
            <p>
              De Wolk heeft een val gedetecteerd, maar kon niet activeren omdat er geen patroon
              aanwezig was. De oorzaak kan zijn dat het patroon al is geactiveerd bij een
              eerdere val, maar nog niet vervangen. Het kan ook zo zijn dat er geen patroon
              in de Wolk zit, of deze is niet goed aangesloten. Vervang het patroon zo snel
              mogelijk, de clïent is nu niet beschermd!
            </p>
          </v-card-text>
        </template>

        <template v-else-if="dialogType === 'nonHipFall'">
          <v-card-title class="headline grey lighten-2">
            Val naar voren of achteren
          </v-card-title>

          <v-card-text class="mt-4">
            <p>
              De Wolk heeft een val naar voren of achteren gedetecteerd. Bij dit type vallen
              biedt een heupairbag geen extra bescherming, omdat de airbags alleen de heupen
              beschermen. De Wolk is dus niet geactiveerd bij deze val.
            </p>
          </v-card-text>
        </template>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="primary"
            text
            @click="dialogOpen = false"
          >
            Sluit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ActivationDetailChip',
  props: {
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      required: true,
    },
    dialogType: {
      type: String,
      default: undefined,
      validator: (value) => value === undefined || [
        'dataStillExpected',
        'dataReceivingExpiredWithActivation',
        'dataReceivingExpiredWithoutActivation',
        'notFallInPostProcessing',
        'notOnTimeActivated',
        'notActivated',
        'notApplicable',
        'nonHipFall',
        'notFallInPostProcessingPostFall',
        'noCartridge',
      ].includes(value),
    },
    tooltip: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
};
</script>
